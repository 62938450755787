var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageWrapperWithSidebar',[_c('Loading',{attrs:{"loading":_vm.loading,"loadingText":_vm.loadingText}}),_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"blnavsecondary pb-0",staticStyle:{"width":"100%"}},[_c('NavbarMain',{attrs:{"showLogo":false,"setLoading":_vm.setLoading}}),_c('div',{staticClass:"quote__container"},[(_vm.isLinkedToRequest)?_c('RequestNav'):_vm._e(),_c('div',{staticClass:"quote_box__container"},[_c('div',[_c('h2',{staticClass:"is-h2 mr-2 pb-3 mb-4"},[_vm._v(" "+_vm._s(_vm.isNewRequest ? "Creating a new Request" : "Editing Request")+" ")]),_c('div',{staticClass:"quote_form__container"},[_c('BaseSelect',{attrs:{"size":"large","list":_vm.clients,"optionValue":"id","optionText":"name","disabled":_vm.loading ||
                  _vm.disableQuoteByStatus ||
                  _vm.hideElementByCondition ||
                  Boolean(_vm.quoteToLinkToRequest) ||
                  _vm.isFormDisabled ||
                  _vm.isClientAndContractDisabled,"placeholder":"Select a client","label":"Client*"},on:{"input":function ($event) { return _vm.loadClientContracts($event); }},model:{value:(_vm.requestForm.client_id),callback:function ($$v) {_vm.$set(_vm.requestForm, "client_id", $$v)},expression:"requestForm.client_id"}}),_c('BaseSelect',{attrs:{"size":"large","list":_vm.client_contracts,"optionValue":"id","optionText":"name","disabled":_vm.loading ||
                  _vm.disableQuoteByStatus ||
                  _vm.hideElementByCondition ||
                  Boolean(_vm.quoteToLinkToRequest) ||
                  _vm.isFormDisabled ||
                  _vm.isClientAndContractDisabled,"placeholder":"Select a contract","label":"Contract*"},on:{"input":function () {}},model:{value:(_vm.requestForm.contract_id),callback:function ($$v) {_vm.$set(_vm.requestForm, "contract_id", $$v)},expression:"requestForm.contract_id"}}),_c('BaseInput',{attrs:{"size":"large","label":"Title","disabled":_vm.disableQuoteByStatus ||
                  _vm.loading ||
                  _vm.hideElementByCondition ||
                  _vm.isFormDisabled,"placeholder":"Type a title"},model:{value:(_vm.requestForm.title),callback:function ($$v) {_vm.$set(_vm.requestForm, "title", $$v)},expression:"requestForm.title"}}),_c('BaseInput',{attrs:{"size":"large","label":"Code","disabled":true,"placeholder":"Type a code"},model:{value:(_vm.requestForm.code),callback:function ($$v) {_vm.$set(_vm.requestForm, "code", $$v)},expression:"requestForm.code"}}),_c('BaseSelect',{attrs:{"size":"large","list":_vm.requestStages,"optionValue":"id","optionText":"name","disabled":_vm.loading ||
                  _vm.disableQuoteByStatus ||
                  _vm.hideElementByCondition ||
                  _vm.isNewRequest ||
                  _vm.isFormDisabled,"placeholder":"Select a stage","label":"Stage"},model:{value:(_vm.requestForm.stage_id),callback:function ($$v) {_vm.$set(_vm.requestForm, "stage_id", $$v)},expression:"requestForm.stage_id"}}),(!_vm.hideElementByCondition)?_c('BaseButton',{staticClass:"w-100 quote_form__button mt-5",attrs:{"action":"tercery","size":"small","loading":_vm.loading,"disabled":_vm.disableQuoteByStatus ||
                  _vm.isRequestFormNotValid ||
                  _vm.isFormDisabled},on:{"click":_vm.onSaveClick}},[_c('i',{attrs:{"slot":"icon"},slot:"icon"},[_c('IconCheck',{staticClass:"icon is-small"})],1),_c('div',{attrs:{"slot":"text"},slot:"text"},[_vm._v(" "+_vm._s(_vm.isNewRequest ? ("Create Request " + (_vm.linkQuoteToRequest ? "and link quote to it" : "")) : "Save Request")+" ")])]):_vm._e()],1)])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }